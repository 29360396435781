<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <span>作业管理/作业批阅/学员作业详情</span>
      </template>
      <template #input>
        <a-button type="primary" class="all_boder_btn" @click="showModal(1)"
          >查看判卷规则</a-button
        >
      </template>
    </HeaderBox>

    <div class="top">
      <div class="topitem">
        <span class="span1"
          ><img
            src="@/assets/image/dataAnalyse/box1_icon8.png"
            alt=""
            width="14"
          />
          学员信息</span
        >
        <span class="span1">{{ project.userName }} {{ project.mobile }}</span>
      </div>
      <div class="topitem">
        <span class="span1"
          ><img
            src="@/assets/image/dataAnalyse/box1_icon9.png"
            alt=""
            width="14"
          />
          提交时间</span
        >
        <span class="span1">{{ project.submitTime }}</span>
      </div>
      <div class="topitem">
        <span class="span1"
          ><img
            src="@/assets/image/dataAnalyse/box1_icon10.png"
            alt=""
            width="14"
          />
          提交状态</span
        >
        <span class="span1">{{ statuslist[project.status] }}</span>
      </div>
    </div>

    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="本次作业">
        <div class="dlex">
          <div class="task">
            <div class="appendix" v-if="attachFlag">
              <div class="taskname">
                <img
                  width="16"
                  src="@/assets/image/subjective/icon-img6.png"
                  alt=""
                />
                作业附件
              </div>
              <div class="attachdata">
                <div
                  v-for="(item, index) in attachdata.doclist"
                  @click="showurl(item.url)"
                  :key="index + 'r'"
                >
                  <img
                    src="@/assets/image/dataAnalyse/box1_icon7.png"
                    alt=""
                    width="14"
                  />
                  {{ item.name }}
                  <a-icon class="icon" type="right" />
                </div>
                <div
                  v-for="(item, index) in attachdata.imglist"
                  :key="index + 'k'"
                >
                  <viewer
                    ><img :src="item.url" alt="" width="36" height="36"
                  /></viewer>
                  <a-icon class="icon" type="right" />
                </div>
                <div
                  v-for="(item, index) in attachdata.pdflist"
                  :key="index + 's'"
                >
                  <img
                    src="@/assets/image/subjective/icon-pdf.png"
                    alt=""
                    width="14"
                  />
                  {{ item.name }}
                  <a-icon class="icon" type="right" />
                </div>
                <div
                  v-for="(item, index) in attachdata.mp4list"
                  @click="showurl(item.url)"
                  :key="index + 'w'"
                >
                  <img
                    src="@/assets/image/dataAnalyse/Vector.png"
                    alt=""
                    width="14"
                  />
                  {{ item.name }}
                  <a-icon class="icon" type="right" />
                </div>
              </div>
            </div>
            <div class="title" v-if="project.answerContent">
              <img
                width="16"
                src="@/assets/image/subjective/icon-img1.png"
                alt=""
              />
              作业文本
            </div>
            <div class="taskname">
              <!-- 特殊属性，可以换行 -->
              <p style="white-space: pre-wrap">
                {{ project.answerContent }}
              </p>
            </div>
            <!-- 上次驳回原因 -->
            <div class="title" v-if="historyReply && project.status != 8">
              <img
                width="16"
                src="@/assets/image/subjective/icon-img6.png"
                alt=""
              />
              上次驳回原因
            </div>
            <div class="taskname">
              {{ historyReply }}
            </div>
          </div>

          <div class="conter">
            <span>
              <img
                width="16"
                src="@/assets/image/subjective/icon-img3.png"
                alt=""
              />
              评分（总分：{{ project.totalScore }}分）
            </span>
            <br /><br />

            <a-input-number
              placeholder="请输入考试分数"
              :disabled="answertype == 2"
              v-model="correct.score"
              :min="0"
              :max="project.totalScore"
              style="width: 200px"
            />
            <br />
            <br />
            <span>
              <img
                width="16"
                src="@/assets/image/subjective/icon-img2.png"
                alt=""
              />
              评语
            </span>
            <br />
            <br />
            <a-textarea
              :min="50"
              placeholder="请输入评语，评语内容不得少于50字"
              :disabled="answertype == 2"
              :rows="4"
              style="width: 504px"
              v-model="correct.reply"
            />
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div class="bottom">
      <a-button
        v-if="answertype != 2 && project.nextId != null"
        type="primary"
        :loading="loading"
        @click="pushData(7)"
      >
        保存并批阅下一个
      </a-button>
      <a-button
        v-if="answertype != 2 && project.nextId == null"
        type="primary"
        :loading="loading"
        @click="pushData(7)"
      >
        保存
      </a-button>
      <a-button v-if="answertype != 2" @click="showModal(0)"> 驳回 </a-button>
      <a-button v-if="answertype == 2" @click="$router.go(-1)"> 返回 </a-button>
    </div>
    <a-modal
      title="提示"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div v-if="ifworkpage" class="workpage">
        <p v-html="workpage"></p>
      </div>
      <div v-else>
        <p>确认驳回作业？驳回后学员将重新作答后提交。</p>
        <div>
          <div class="txttip">驳回原因</div>
          <a-input class="nput" placeholder="请输入驳回原因" v-model="reply" />
        </div>
      </div>
    </a-modal>

    <a-modal
      style="width: 800px"
      title="视频"
      :visible="mvisible"
      @ok="mvisible = false"
      @cancel="mvisible = false"
    >
      <p class="videobox" v-if="mvisible">
        <video controls="" ref="videoPlay" autoplay="" name="media">
          <source :src="videoUrl" type="video/mp4" />
        </video>
      </p>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import { viewHeic } from '@/unit/fun.js'

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loading: false, // 列表加载
      visible: false,
      answertype: null, // 状态，1可以批阅，2不可以批阅
      answerId: null,
      reply: null, // 驳回原因
      project: {},
      statuslist: [
        "未提交",
        "暂存",
        "测试",
        "已提交",
        "测试",
        "已驳回",
        "已驳回",
        "已审批",
        "成绩发布",
      ],
      // 作业数据
      attachdata: {
        imglist: [],
        mp4list: [],
        doclist: [],
        pdflist: [],
      }, // 作业附件列表
      // 作业批改详情
      correct: {
        answerContent: null, //作业答案
        answerId: null, //学生作业id
        attachlist: null, //作业附件
        homeworkConfigId: null, //作业配置id
        reply: "", // 作业评论
        score: null, // 分数
        status: null, // 作业状态 0-未提交 1-暂存 3-已提交 5-已驳回 7-已审批 8-成绩发布
        teacherId: null, // 批改作业老师id
        teacherName: null, // 批改作业老师名称
      },
      workpage: null, //批改配置
      ifworkpage: 0, //判断弹框内展示
      mvisible: false,

      // 视频链接
      videoUrl: "",
      // 是否有附件
      attachFlag: false,

      historyReply: "",
    };
  },
  // 事件处理器
  methods: {
    // 操作弹框
    showModal(e) {
      this.ifworkpage = e;
      this.visible = true;
    },
    handleOk(e) {
      // 展示另一个样式时的关闭
      if (this.ifworkpage) {
        this.visible = false;
        return;
      }
      // 防止用户多次点击
      if (this.loading == true) {
        return;
      }
      // 评论必填
      if (!this.reply) {
        this.$message.warning("请输入驳回原因");
        this.loading = false;
        return;
      }
      // 接口赋值
      this.correct.reply = this.reply;
      this.correct.status = 5;
      this.correct.answerId = this.answerId; // id
      this.correct.attachlist = this.project.attachlist; // id
      this.correct.homeworkConfigId = this.project.homeworkConfigId; // id
      this.correct.answerContent = this.project.answerContent; // id

      // 在将要走接口的时候将值设为false
      this.loading = true;
      this.$ajax({
        method: "put",
        url: "/hxclass-management/homeworkAnswer/correct",
        params: this.correct,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          // 判断是否是最后一个，如果是假则返回列表页
          this.$router.push({
            query: {
              id: this.project.nextId,
              type: 1,
            },
          });
          location.reload();
          this.loading = false;
        } else {
          if (this.project.nextId == null) {
            this.$message.success("所有提交的作业已完成批阅");
            this.$router.go(-1);
            return;
          } else {
            this.$message.error(res.message);
            this.$router.go(-1);
          }
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    // 查询详情
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/homeworkAnswer/userAnswer/details",
        params: {
          answerId: this.answerId,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          // 如果有文本则对其做一定处理，使其能展示样式换行
          if (res.data.answerContent) {
            res.data.answerContent = res.data.answerContent.replace(/\n/g, "<br>");
          }
          this.project = res.data;
          // console.log(this.project.answerContent);
          // 解析文件,为null下不走
          if (res.data.attachlist != null) {
            this.parsefile(JSON.parse(res.data.attachlist));
          }
          // 获取批改规则
          this.getPage(res.data.homeworkConfigId);
          // 如果是查看作业，则赋值
          if (this.answertype == 2) {
            this.correct.reply = res.data.reply;
            this.correct.score = res.data.score;
          }
          if (res.data.status != 8) {
            this.historyReply = res.data.reply;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 解析文件
    parsefile(e) {
      // 没有数据
      if (!e.length) {
        this.attachFlag = false;
        return;
      } else {
        this.attachFlag = true;
      }
      let Ject = this.attachdata;
      let imgSuffix = ["jpg", "png", "gif", "jpeg", "heic"];
      // 使用 continue 以节省性能
      for (let i = 0; i < e.length; i++) {
        if (e[i].url) {
          let lasti = e[i].url.lastIndexOf(".");
          let suffix = e[i].url.substring(lasti + 1);
          if (
            suffix == "doc" ||
            suffix == "docx" ||
            suffix == "txt" ||
            suffix == "xls" ||
            suffix == "xlsx"
          ) {
            Ject.doclist.push(e[i]);
            continue;
          } else if (suffix == "mp4") {
            Ject.mp4list.push(e[i]);
            continue;
          } else if (suffix == "pdf") {
            Ject.pdflist.push(e[i]);
            continue;
          } else if (imgSuffix.indexOf(suffix) != -1) {
            Ject.imglist.push(e[i]);
            continue;
          }
        } else {
          return false;
        }
      }
      this.attachdata = Ject;
      // console.log(this.attachdata, Ject);
      if(this.attachdata.imglist.length){
        this.attachdata.imglist.forEach((element,index) => {
          viewHeic(element.url).then((res)=>{
            element.url = res
          })
        });
      }
    },
    // 查询批改配置
    getPage(e) {
      this.$ajax({
        method: "get",
        url: "/hxclass-management/homeworkAnswer/config/data",
        params: {
          homeworkConfigId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.workpage = res.data.correctNote;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 提交数据
    pushData(e) {
      // 防止用户多次点击
      if (this.loading == true) {
        return;
      }

      if (!this.correct.score) {
        this.$message.warning("请输入考试分数");
        this.loading = false;
        return;
      }
      // 评论必填
      if (!this.correct.reply) {
        this.$message.warning("请输入作业评论");
        this.loading = false;
        return;
      }
      // 接口赋值
      this.correct.answerId = this.answerId; // id
      this.correct.status = e; // 状态
      this.correct.attachlist = this.project.attachlist; // id
      this.correct.homeworkConfigId = this.project.homeworkConfigId; // id
      this.correct.answerContent = this.project.answerContent; // id
      // 在将要走接口的时候将值设为false
      this.loading = true;
      this.$ajax({
        method: "put",
        url: "/hxclass-management/homeworkAnswer/correct",
        params: this.correct,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          // 判断是否是最后一个，如果是假则返回列表页
          this.$router.push({
            query: {
              id: this.project.nextId,
              type: 1,
            },
          });
          location.reload();
          this.loading = false;
        } else {
          if (this.project.nextId == null) {
            this.$message.success("所有提交的作业已完成批阅");
            this.$router.go(-1);
            return;
          } else {
            this.$message.error(res.message);
            this.$router.go(-1);
          }
        }
      });
    },
    // 预览文件
    showurl(url) {
      let lastIndex = url.lastIndexOf(".");
      let suffix = url.substring(lastIndex + 1);
      if (suffix == "mp4") {
        // console.log(url);
        this.videoUrl = url;
        this.mvisible = true;
        return;
      }
      if (suffix == "doc" || suffix == "docx" || suffix == "txt") {
        window.open(
          url +
            "?ci-process=doc-preview&dstType=html&copyable=0&htmlParams=eyJwZGZPcHRpb25zIjp7ImlzSW5TYWZlTW9kZSI6dHJ1ZX19"
        );
      } else {
        window.open(url);
      }
    },
    callback(key) {
      // console.log(key);
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.answerId = this.$route.query.id;
    this.answertype = this.$route.query.type;
    // console.log(this.answertype);
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    mvisible(e, r) {
      // console.log(e, r);
      if (!e) {
        // video.pause();
        this.$refs["videoPlay"].pause();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  // width: 1113px;
  overflow: hidden;
  height: 44px;
  margin-top: 16px;
  background: #f6f6fc;
  border-radius: 5px 5px 5px 5px;
  display: flex;
  align-items: center;
  padding: 15px;
  .topitem {
    width: 33%;
    display: flex;
    align-items: center;
    .span1 {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;
      > img {
        margin-right: 6px;
      }
      margin-right: 16px;
    }
  }
}
.title {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-top: 20px;
}
.task {
  overflow: hidden;
  background: #f6f6fc;
  border-radius: 5px 5px 5px 5px;
  padding: 15px;
  width: 60%;
  background: #ffffff;
  .taskname {
    font-family: PingFang SC-Regular, PingFang SC;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    margin-right: 10px;
  }
  .attachdata {
    font-family: PingFang SC-Regular, PingFang SC;
    > div {
      margin: 5px 0;
      display: flex;
      align-items: center;
      span > {
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-right: 10px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
}
.appendix {
  overflow: hidden;
  min-width: 225px;
  .taskname {
    font-family: PingFang SC-Regular, PingFang SC;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;
    color: #333333;
    margin-right: 10px;
  }
  .attachdata {
    font-family: PingFang SC-Regular, PingFang SC;
    > div {
      width: 500px;
      height: 46px;
      position: relative;
      background: #f6f6fc;
      padding: 5px 8px;
      .icon {
        position: absolute;
        right: 10px;
      }
      img {
        width: 36px;
        height: 36px;
        margin-right: 8px;
      }
    }
  }
}
.conter {
  // height: 245px;
  width: 40%;
  flex-shrink: 0;
  background: #ffffff;
  border-radius: 5px 5px 5px 5px;
  margin-left: 16px;
  padding: 15px;
}
.bottom {
  padding-top: 20px;
  text-align: right;
  height: 60px;
  .ant-btn {
    margin-left: 15px;
  }
}
.dlex {
  padding: 15px;
  background: #f6f6fc;
  display: flex;
}
.videobox {
  overflow: hidden;
  text-align: center;
  > video {
    max-width: 600px;
    max-height: 600px;
    margin: 0 auto;
  }
}
.txttip {
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 20px 0;
}
/deep/.ant-modal-content {
  width: 800px;
}
/deep/.workpage {
  img {
    max-width: 100%;
  }
}
</style>